<template>
  <hotel-profile-component @update-title="changeTitle" />
</template>

<script>
import HotelProfileComponent from "@/components/hotels/HotelProfileComponent";

export default {
  name: "HotelProfile",
  title: "Perfil del Hotel | Baja California Health Tourism",
  components: { HotelProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
