<template>
    <v-card class="rounded-lg hotel-card" elevation="10" height="auto" hover outlined
        width="100%" v-on:click="showProfile(item.slu)">
        <v-img :lazy-src="require('@/assets/images/no-image.jpg')" :src="item.pictures_portal[0] != null
                ? item.pictures_portal[0].url
                : require('@/assets/images/no-image.jpg')
            " class="white--text align-end justify-start zoom-image"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)" height="300" position="center">
            <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                    <v-progress-circular color="grey lighten-5" indeterminate>
                    </v-progress-circular>
                </v-row>
            </template>

            <div class="white--text mx-2">
                <p class="text-left font-weight-light text-h5">
                    {{ item.name }}
                </p>
            </div>
        </v-img>
        <TypeEnityDiscView :item="item" />
    </v-card>
</template>
<script>
import TypeEnityDiscView from "@/components/type-entity-view/TypeEnityDiscView";

export default {
    name: "CardHotel",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    components: {
        TypeEnityDiscView
    },
    methods: {
        showProfile(idHotel) {
            this.$router.push({
                name: "HotelProfile",
                params: {
                    id: idHotel,
                },
            });
            this.$emit("update-route", { id: idHotel });
        },
    }
}
</script>
<style>
.hotel-card {
    border-radius: 8px !important;
}
.top-left {
    position: absolute;
    top: 3px;
    left: 3px;
    width: auto;
    height: 65px;
    border-radius: 50% !important;
}
.superior-derecha {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 80px;
    height: 80px;
}

.superior-derecha-segunda {
    position: absolute;
    top: 73px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
}

.superior-derecha-tercera {
    position: absolute;
    top: 145px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
}

.superior-derecha-cuarta {
    position: absolute;
    top: 230px;
    right: 3px;
    width: 57px;
    height: 57px;
    border-radius: 50%;
}
</style>
