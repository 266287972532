<template>
    <v-col cols="12">
        <div class="div-qq mt-1">
            <div>
                <img title="Different disabilities" src="@/assets/images/icons/prevented.jpeg" alt="sello1"
                    class="img-dd" />
            </div>
            <div class="ml-1">
                <p class="text-button font-weight-small">
                    Inclusion for all people, regardless of abilities
                </p>
            </div>
        </div>
    </v-col>
</template>
<script>
export default {
    name: "DifferentDisabilities",
}
</script>
<style>
.img-dd{
    width: 60px;
    height: auto;
    border-radius: 50%;
}
.div-qq{
    display: flex;
}
</style>